import React from "react";
import TrailingParagraph from "../animations/TrailingParagraph";
import IntersectionBox from "../animations/IntersectionBox";
import RenderOnIntersectionBox from "../animations/RenderOnIntersectionBox";
import {
  zoomInWithDelay,
  fadeInFromBottomWithDelay,
  fadeIn,
  zoomOutWithDelay,
} from "../animations/variants";
import { useStaticQuery, graphql } from "gatsby";

function HowItWorksPartner() {
  const data = useStaticQuery(graphql`
    query {
      strapi {
        partnerNetwork {
          How_it_works_partner {
            How_it_works_header
          }
          process_steps {
            description
            graphic {
              image {
                url
                alternativeText
              }
            }
            heading
          }
        }
      }
    }
  `);

  const node = data.strapi.partnerNetwork;

  return (
    <div className="section-3">
      <div className="content">
        <div className="process-info">
          <p className="process-head">
            <TrailingParagraph
              paragraph={node.How_it_works_partner.How_it_works_header}
            />
          </p>

          <div className="row illus-row">
            {node.process_steps.map((step, index) => (
              <div className="process-row col-lg-3 col-md-3 col-sm-6 col-xs-12">
                <RenderOnIntersectionBox
                  variants={zoomInWithDelay(0.2 * index + 0.1)}
                >
                  <figure>
                    <img
                      src={step.graphic.image.url}
                      className="fadeInUp"
                      style={{ animationDelay: `${750 + index * 250}ms` }}
                      alt={step.heading}
                    />
                  </figure>

                  <div className="step-num-container">
                    <div className="step-num"> {index + 1} </div>
                  </div>
                  <div
                    className={`steps step-${index} fadeInDown`}
                    style={{ animationDelay: `${750 + index * 250}ms` }}
                  >
                    <div className="step-info">
                      <p className="step-head">
                        <TrailingParagraph paragraph={step.heading} />
                        {/* {step.heading} */}
                      </p>

                      <p className="step-desc">
                        <TrailingParagraph paragraph={step.description} />
                        {/* {step.description} */}
                      </p>
                    </div>
                  </div>
                </RenderOnIntersectionBox>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default HowItWorksPartner;
