import React from "react";
import SEO from "../components/seo";
import Layout from "../components/layout";
import HowItWorksPartner from "../components/HowItWorksPartner";
import GetQuoteSection from "../components/GetQuoteSection";
import TrailingParagraph from "../animations/TrailingParagraph";
import IntersectionBox from "../animations/IntersectionBox";
import RenderOnIntersectionBox from "../animations/RenderOnIntersectionBox";
import { zoomInWithDelay, zoomOutWithDelay } from "../animations/variants";
import { useStaticQuery, graphql } from "gatsby";
import { useForm } from "react-hook-form";
import axios from "axios";
import { motion } from "framer-motion";

function PartnerNetwork() {
  const data = useStaticQuery(graphql`
    query {
      strapi {
        partnerNetwork {
          banner_image {
            url
            alternativeText
          }
          become_partner {
            description_text
            heading_text
          }
          partner_usps {
            logo_title
            logo_image {
              image {
                url
                alternativeText
              }
            }
          }
          seo {
            meta_description
            meta_keywords
            title
          }
        }
      }
    }
  `);

  const node = data.strapi.partnerNetwork;

  const { register, errors, handleSubmit } = useForm();
  const [submitted, setSubmitted] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);

  async function submit(data) {
    setSubmitting(true);
    try {
      const res = await axios.post("https://api.custiv.com/api/forms/partner", {
        partner: data,
      });
    } catch (error) {}
    setSubmitting(false);
    setSubmitted(true);
  }

  return (
    <Layout>
      <SEO
        title={node.seo.title}
        description={node.seo.meta_description}
        keywords={node.seo.meta_keywords}
      />
      <div className="content">
        <div className="parts-section">
          <p className="parts-head" data-sal="slide-up" data-sal-delay="300">
            <TrailingParagraph paragraph={node.become_partner.heading_text} />
          </p>
          <p className="parts-desc" data-sal="slide-up">
            <TrailingParagraph
              paragraph={node.become_partner.description_text}
            />
          </p>
          <div className="partner-network-img">
            {/* <img src={"/images/mauf_machine.png"} />
             */}
            <IntersectionBox variants={zoomOutWithDelay(0.1)}>
              <img
                src={node.banner_image.url}
                className="partner-network-image"
                alt="Partner Network"
              />
            </IntersectionBox>
          </div>
          <div className="container-fluid">
            <p className="parts-head" data-sal="slide-up" data-sal-delay="300">
              <TrailingParagraph paragraph="Advantages with Custiv" />
            </p>
            <RenderOnIntersectionBox>
              <div className="row quality-row">
                {node.partner_usps.map((usp, index) => (
                  <IntersectionBox
                    variants={zoomInWithDelay(0.2 + index * 0.3)}
                  >
                    <div className="column col-lg-3 col-md-3 col-sm-3 col-xs-6">
                      <div className="qual-icon">
                        <figure>
                          <img
                            src={usp.logo_image.image.url}
                            alt={usp.logo_title}
                          />
                        </figure>
                      </div>
                      <p className="qual-desc">{usp.logo_title}</p>
                    </div>
                  </IntersectionBox>
                ))}
              </div>
            </RenderOnIntersectionBox>
          </div>
        </div>
        <HowItWorksPartner />
        <IntersectionBox>
          <div className="partner-section">
            <div className="req-section">
              <p className="partner-custiv"> Partner with Custiv</p>
              {submitted && (
                <motion.div
                  className="callback-form-submitted"
                  initial="hidden"
                  animate="show"
                  variants={zoomInWithDelay(0.1)}
                >
                  <TrailingParagraph
                    paragraph={`Thank you for your interest in partnering with Custiv. We have received your request. Our team will call you shortly.`}
                  />
                </motion.div>
              )}
              {!submitted && (
                <form onSubmit={handleSubmit(submit)}>
                  <div className="req-subsection">
                    <div className="top-row">
                      <input
                        type="text"
                        name="name"
                        placeholder="Your Name"
                        id="fname"
                        required
                        ref={register}
                      />
                      <input
                        type="email"
                        name="email"
                        placeholder="Your Email ID"
                        id="email"
                        required
                        ref={register}
                      />
                    </div>
                    <div className="top-row">
                      <input
                        type="text"
                        name="phNumber"
                        placeholder="Your Number"
                        id="phnumber"
                        required
                        ref={register}
                      />
                      <input
                        type="text"
                        name="companyName"
                        placeholder="Company Name"
                        id="compname"
                        ref={register}
                      />
                    </div>
                    <div className="top-row">
                      <input
                        type="text"
                        name="country"
                        placeholder="Country"
                        id="country"
                        required
                        ref={register}
                      />
                      <input
                        type="text"
                        name="addDetails"
                        placeholder="Additional Details"
                        id="addDetails"
                        ref={register}
                      />
                    </div>
                  </div>
                  <button className="clippy_button request-button">
                    {submitting ? (
                      <div className="lds-ellipsis">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                    ) : (
                      "Submit Request"
                    )}
                  </button>
                </form>
              )}
            </div>
          </div>
        </IntersectionBox>
      </div>
    </Layout>
  );
}

export default PartnerNetwork;
